<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <SubjectForm :subject="subject" />
  </main>
</template>

<script>
import Subject from '../../../store/models/Subject';

import SubjectForm from '../../../components/admin/subject/SubjectForm.vue';

export default {
  components: { SubjectForm },

  computed: {
    subject() {
      return Subject.query().withAll().find(this.$route.params.id);
    },
  },

  metaInfo() {
    return { title: this.subject && this.subject.name };
  },

  beforeMount() {
    if (!this.subject) {
      this.$store.dispatch('subjects/fetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/pelajaran', label: 'Pelajaran' },
    ]);
  },
};
</script>
